import { ITrackerContext } from 'com/didichuxing/tracker/context/ITrackerContext';
import { IEventReportor } from 'com/didichuxing/tracker/plugin/reportor/IEventReportor';
import PostEventReportor from 'com/didichuxing/tracker/plugin/reportor/PostEventReportor';
import AOMGH5LogPlugin from 'com/didichuxing/tracker/plugins/AOMGH5LogPlugin';
import { ITrackedEvent } from 'com/didichuxing/tracker/event/ITrackedEvent';

export default class OMGH5LogPlugin extends AOMGH5LogPlugin {

    /**
     * @override
     * @inheritDoc
     * @returns {IEventReportor}
     */
    protected getEventReportor(event: ITrackedEvent): IEventReportor {
        return new PostEventReportor(this.getContext() as ITrackerContext, event);
    }
}
