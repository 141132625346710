export default abstract class EncodeHash {
    /**
     * 对输入字符串进行Hash编码。
     * @param {string} str
     * @returns {number}
     */
    public static encodeHash(str: string): number {
        let hash: number = 1315423911;
        for (let i: number = 0, j: number = str.length; i < j; ++i) {
            hash ^= ((hash << 5) + str.charCodeAt(i) + (hash >> 2));
        }
        return hash & 0x7FFFFFFF;
    }

    /**
     * 输出增加tk的URL
     * @param str
     */
    public static encodeHashUrl(str: string): string {
        return `${ str }&tk=${ this.encodeHash(str) }`;
    }
}
