import AH5EventReportor from 'com/didichuxing/tracker/plugin/reportor/AH5EventReportor';
import { ITrackedEvent } from 'com/didichuxing/tracker/event/ITrackedEvent';
import FailedEvent from 'com/didichuxing/tracker/plugin/events/FailedEvent';
import TimeoutEvent from 'com/didichuxing/tracker/plugin/events/TimeoutEvent';
import ReportCallbackUtil from 'com/didichuxing/tracker/plugin/utils/ReportCallbackUtil';
import EncodeHash from 'com/didichuxing/tracker/plugin/utils/EncodeHash';
import GlobalUtil from 'com/didichuxing/tracker/plugin/utils/GlobalUtil';

/**
 * 上报超时默认时间，单位毫秒。
 * @type {number}
 */
const REPORT_TIMEOUT: number = 1500;

const forbidReportEventListKey = '__OMG_FORBID_EVENTLIST__';

export default class PostEventReportor extends AH5EventReportor {

    /**
     * 标记
     * - 是否开始上报数据
     */
    private startForbidReport: boolean = false;

    /**
     * 将上报的数据存储到global中
     */
    private get forbidReportEventList(): ITrackedEvent[] {
        if (!(window as any)[forbidReportEventListKey]) {
            (window as any)[forbidReportEventListKey] = [];
        }
        return (window as any)[forbidReportEventListKey];
    }

    private addForbidEvent = (event: ITrackedEvent) => {
        if (this.forbidReportEventList.length > 50) {
            return;
        }
        this.forbidReportEventList.push(event);
    }
    /**
     * 修改禁止上报为开启上报
     */
    private onChangeForbidFalse = () => {
        // 若已经开始则return
        if (this.startForbidReport) {
            return;
        }
        this.startForbidReport = true;
        this.reportForBidEvent();
    }
    private reportForBidEvent = () => {
        const context = this.getContext();
        // 防止批量上报过程中禁止上报
        if (context.forbidReport) {
            return;
        }
        if (this.forbidReportEventList && this.forbidReportEventList.length) {
            const event =  this.forbidReportEventList.pop();
            if (event) {
                // 防止一次请求过多一条条进行上报
                this.reportEventImmediately(event)
                .then(() => {
                    this.reportForBidEvent();
                }).catch(() => {
                    this.reportForBidEvent();
                });
            }
        }
    }
    /**
     * 根据当前的时间进行cityID的加密
     */
    private getOmgci(): string {
        if (this.getContext().cityId) {
            const cId = Number(this.getContext().cityId);
            /**
             * 确保当前值符合要求
             */
            if (!isNaN(cId)) {
                const len = String(cId).length;
                let ts = `${new Date().getTime()}`;
                ts = ts.slice(0, -(len + 1));
                ts = `${ts}${cId}${len}`;
                return ts;
            }
        }
        return '';
    }

    /**
     * @override
     * @param {ITrackedEvent} event
     * @returns {Promise<ITrackedEvent>}
     */
    protected reportEventImmediately(event: ITrackedEvent): Promise<ITrackedEvent> {
        const context = this.getContext();
        // 禁止上报则直接return
        if (context.forbidReport) {
            // 将上报标记设置为false
            this.startForbidReport = false;
            this.addForbidEvent(event);
            return Promise.resolve(event);
        } else {
            this.onChangeForbidFalse();
        }
        return new Promise<ITrackedEvent>((resolve: (evt: ITrackedEvent) => void,
                                           reject: (evt: ITrackedEvent) => void): void => {
            const URL = `${GlobalUtil.getProtocol()}${this.getContext().uploadHost}${this.reportPath}`;
            const xhr = new XMLHttpRequest();
            const eventData = event.getData();
            xhr.timeout = this.getContext().timeout || REPORT_TIMEOUT;
            xhr.ontimeout = () => {
                reject(new TimeoutEvent(event.getType(), eventData.attrs || {}, this.getContext()));
            };
            xhr.open('POST', URL + `?e=${event.getType()}`);
            xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=utf-8');
            xhr.setRequestHeader('oid', eventData.omgId || '');
            xhr.setRequestHeader('seq', eventData.appKey || '');
            const cid = this.getOmgci();
            if (cid) {
                xhr.setRequestHeader('omgci', cid);
            }
            // 为请求设置为唯一值
            xhr.setRequestHeader(
                'msgid',
                `${eventData.viewportId || ''}-${eventData.sequence || ''}-${eventData.timestamp || ''}`
            );
            xhr.send(EncodeHash.encodeHashUrl(event.serialize()));
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4 && xhr.status === 200) {
                    try {
                        const response = JSON.parse(xhr.responseText);
                        if (response.state && response.state.code !== 200) {
                            if (response.state.code === 500) {
                                this.errorreportedEvents.add(event);
                            }
                            GlobalUtil.dispatchOmgLog(`POST 200 上报错误${event.getType()}_
                            ${response.state.code || 0}_${response.state.msg || ''}`);
                        }
                        ReportCallbackUtil.reportCallback(response);
                        resolve(event);
                    } catch (error) {
                       // 解析返回值失败但是不抛异常
                       console.error(`上报成功后解析失败:${xhr.responseText}`);
                    }
                }
            };
            xhr.onerror = () => {
                if (xhr.status === 500) {
                    this.errorreportedEvents.add(event);
                } else {
                    console.error('POST上报失败onerror' + xhr.status);
                }
                reject(new FailedEvent(new Error('POST Error'), event.getType(),
                    eventData.attrs || {}, this.getContext()));
            };
        });
    }
}
