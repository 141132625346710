import { ITrackedEvent } from 'com/didichuxing/tracker/event/ITrackedEvent';

export default class DataQueueSet {
    private static instance: DataQueueSet;
    private arr: ITrackedEvent[] = [];

    private constructor() {
    }

    public static getInstance(): DataQueueSet {
        if (!DataQueueSet.instance) {
            DataQueueSet.instance = new DataQueueSet();
        }
        return this.instance;
    }

    /**
     * 是否重复
     */
    private uniq(value: ITrackedEvent): boolean {
        let result = true;
        this.arr.forEach((item) => {
            if (value.getData().viewportId === item.getData().viewportId &&
                value.getData().sequence === item.getData().sequence) {
                result = false;
            }
        });
        return result;
    }

    /**
     * 添加
     */
    public add(value: ITrackedEvent): void {
        if (this.uniq(value)) {
            this.arr.push(value);
        }
    }

    /**
     * 是否为空
     */
    public empty(): boolean {
        return this.arr.length === 0;
    }

    /**
     * 删除所有
     */
    public removeAll(): void {
        this.arr = [];
    }

    /**
     * 返回数组
     */
    public getData(): ITrackedEvent[] {
        return this.arr;
    }

}
