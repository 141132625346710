import { ITrackedEvent } from 'com/didichuxing/tracker/event/ITrackedEvent';
import { ITrackedEventArgs } from 'com/didichuxing/tracker/plugin/IReportingStrategyPlugin';
import OMGH5LogEvent from 'com/didichuxing/tracker/events/OMGH5LogEvent';
import AReportingStrategyPlugin from 'com/didichuxing/tracker/plugin/AReportingStrategyPlugin';
import PluginUtils from 'com/didichuxing/tracker/plugin/utils/PluginUtils';

export default abstract class AOMGH5LogPlugin extends AReportingStrategyPlugin {
    /**
     * @override
     * @inheritDoc
     * @returns {boolean}
     */
    public install(): boolean {
        window.addEventListener('omglog', (e) => {
            this.reportLog(e);
        });
        return super.install();
    }

    /**
     * @override
     * @returns {string}
     */
    public getName(): string {
        return PluginUtils.PLUGIN_NAME_OMGLOG;
    }

    /**
     * @override
     * @inheritDoc
     */
    public execute(): void {
        /* Nothing to execute */
    }

    /**
     * @override
     * @inheritDoc
     */
    public exit(): void {
        window.removeEventListener('omglog', this.reportLog);
    }

    /**
     * 发送log
     * @param e
     */
    public reportLog(e: any): void {
        this.report({
            eventId: PluginUtils.EVENT_ID_OMG_LOG,
            attrs: {
                trackerVersion: this.getContext().version,
                log: '[Omega]' + (e.data || '')
            }
        });
    }

    /**
     * @override
     * @inheritDoc
     * @param {ITrackedEventArgs} eventArgs
     * @returns {ITrackedEvent}
     */
    protected generateEvent(eventArgs: ITrackedEventArgs): ITrackedEvent {
        return new OMGH5LogEvent(eventArgs.attrs, this.getContext());
    }
}
