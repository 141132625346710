export interface IReportCallbackArg {

    /**
     * 上报事件的名单。
     * @readonly
     */
    readonly eventid: IReportableEventList | null;
}

export interface IReportableEventList {

    /**
     * 上报事件id
     * 0为禁止上报，1为允许上报。
     * @readonly
     */
    readonly [eventid: string]: number;
}

export default abstract class ReportCallbackUtil {

    /**
     * response回调方法挂载的对象名称。
     * @type {string}
     */
    public static readonly OMG_CALLBACK_OBJ_NAME: string = '__OMG_CBKS__';

    /**
     * omega事件黑名单列表对象名称。
     * @type {string}
     */
    public static readonly OMG_EVENT_BLACKLIST_NAME: string = '__OMG_EVENT_BLACKLIST__';

    public static reportCallbackName(rawSerialized: string): string {
        const cbObjName: string = ReportCallbackUtil.OMG_CALLBACK_OBJ_NAME;
        const cbkMethodName: string = `_cbk${ new Date().getTime() }`;
        rawSerialized = `${rawSerialized}&cbk=${ cbObjName }.${ cbkMethodName }`;
        const self = this;
        if ((window as any)[cbObjName]) {
            (window as any)[cbObjName][cbkMethodName] = function() {
                delete (window as any)[cbObjName][cbkMethodName];
                self.reportCallback.apply(self, arguments);
            };
        } else {
            (window as any)[cbObjName] = {
                [cbkMethodName]: function() {
                    delete (window as any)[cbObjName][cbkMethodName];
                    self.reportCallback.apply(self, arguments);
                }
            };
        }
        return rawSerialized;
    }
    /**
     * 上报后的回调。
     * @param {IReportCallbackArg} cbArg
     */
    public static reportCallback(cbArg?: IReportCallbackArg): void {
        const bn: string = ReportCallbackUtil.OMG_EVENT_BLACKLIST_NAME;
        if (cbArg && cbArg.eventid) {
            if (!(window as any)[bn]) {
                (window as any)[bn] = [];
            }
            for (const prop in cbArg.eventid) {
                if (cbArg.eventid[prop] === 0) { // event black list
                    if (((window as any)[bn] as string[]).indexOf(prop) < 0) {
                        // not existed
                        ((window as any)[bn] as string[]).push(prop);
                    }
                } else { // event need to be removed from black list
                    const index: number = ((window as any)[bn] as string[]).indexOf(prop);
                    if (index >= 0) { // existed
                        ((window as any)[bn] as string[]).splice(index, 1);
                    }
                }
            }
        }
    }
}
