import SequencedEvent from 'com/didichuxing/tracker/plugin/events/SequencedEvent';
import PluginUtils from 'com/didichuxing/tracker/plugin/utils/PluginUtils';

export default class OMGH5LogEvent extends SequencedEvent {

    /**
     * @override
     * @inheritDoc
     * @returns {string}
     */
    public getType(): string {
        return PluginUtils.EVENT_ID_OMG_LOG;
    }
}
